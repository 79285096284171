import React, { useEffect, useState } from 'react';
import { useAPI } from '../utils/api';
// import { setToken } from '../utils/token';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import WebFn, { callAppFunc } from '../utils/jsbridge/index.js';
import Modal from 'react-modal';
import Alert from '../components/dialog';

const Sample = () => {
  const api = useAPI();
  // const token = useSelector((state) => state.token.data);
  const [test, setTest] = useState('v1/qc/sfcc/baskets/products/list');
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const showAlert = (event) => {
    event.preventDefault();
  };

  const requestAPI = (event) => {
    event.preventDefault();
    api
      .getInfo()
      .then((res) => {
        console.log(res);
        alert('success');
        // setToken('XXXXXXXXX');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const goToSample = (event) => {
    event.preventDefault();
    navigate('/sample2/', {
      state: { data: '111' },
      replace: false,
    });
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const callApp = (event) => {
    const jsonStr = {
      params: {
        from: 'Web',
      },
    };
    callAppFunc('showDispatchSetting', JSON.stringify(jsonStr));
  };

  const callPost = (evnet) => {
    console.log('value', test);
    api
      .getSamplePost(test)
      .then((res) => {
        console.log(res);
        alert('success');
        // setToken('XXXXXXXXX');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const callGet = (evnet) => {
    console.log('value', test);
    api
      .getSampleGet(test)
      .then((res) => {
        console.log(res);
        alert('success');
        // setToken('XXXXXXXXX');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  // const getData = (strJson) => {
  //   alert(strJson);
  //   console.log('data', strJson);
  // };

  // WebFn({ callback: getData });

  return (
    <>
      <div>
        <h1>範例</h1>
        <button onClick={requestAPI}>RequestAPI</button>
        <br />
        <br />
        <button onClick={goToSample}>go2Sample2</button>
        <br />
        <br />
        <button onClick={callApp}>callApp</button>
        <br />
        <br />

        {/* <form action="" method="post" className="item">
          <input className="inputss" />
        </form> */}
        <input
          className="inputss"
          value={test}
          onChange={(e) => setTest(e.target.value)}
        />
        <button onClick={callPost}>Post</button>
        <button onClick={callGet}>Get</button>
        <br />
        <br />
        {/* <Alert onRight={() => {

        }} /> */}
        {/* <div>
          <button onClick={openModal}>Open Modal</button>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
     
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
            <button onClick={closeModal}>close</button>
            <div>I am a modal</div>
            <form>
              <input />
              <button>tab navigation</button>
              <button>stays</button>
              <button>inside</button>
              <button>the modal</button>
            </form>
          </Modal>
        </div> */}
      </div>
      <style jsx>{`
        .item {
          width: 100%;
          background: khaki;
        }
        .inputss {
          width: 100%;
        }
      `}</style>
    </>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default Sample;
